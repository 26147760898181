'use strict'
angular.module('cb.ipad-service', [])
	.factory('IpadService', ["$rootScope", "ApiService", "SessionService", "SwService", function ($rootScope,ApiService,SessionService, SwService) {

		const allDatadownload = function () {
			ipadService.requestAllData();
		};
		addEventListener('online', function (event) {
			console.log(event);
			SwService.sendAction({ action: 'syncOfflineDB', probs: localStorage.getItem('userId') });
			allDatadownload();
		});


		// A pereodic request for data
		setInterval(allDatadownload, 1000 * 60 * 30) // 1000ms * 60 * 30 = 30 mins

		var ipadService = {
			setOfflineFlag:function(){
				const d = SwService.sendAction({
					action:'pollAPI'
				});
				d.then((data)=>{
					$rootScope.onLineFlag =data;
				},(err)=> {
					$rootScope.onLineFlag = false;

				} )

			},
			requestAllData: function () {
				return SwService.sendAction(
					{
						action: 'requestData',
						userId: localStorage.getItem('userId')
					})
			},
			getPicklist: function () {
				return SwService.sendAction({ action: 'getPicklists' });
			},
			getStatusLists: function () {
				return SwService.sendAction({ action: 'getStatusLists' });
			},
			getStatusGroups: function () {
				return SwService.sendAction({ action: 'getStatusGroups' });
			},
			getLeadProviders: function () {
				return SwService.sendAction({ action: 'getLeadProviders' });
			},
			getLeadProfiles: function () {
				return SwService.sendAction({ action: 'getLeadProfiles' });
			},
			getLeadProfile: function (id) {
				return SwService.sendAction({ action: 'getLeadProfile', probs: id });
			},
			SearchLeadProviders: function (input) {
				return SwService.sendAction({ action: 'SearchLeadProviders', probs: input });
			},
			getSalesmanReport: function (id) {
				return SwService.sendAction({ action: 'getSalesmanReport'})
			},
			getFitterReport: function (id) {
				return SwService.sendAction({ action: 'getFitterReport'})
			},
			getSalesOrder: function (id) {
				//console.log('getSalesOrder'+ id)
				return SwService.sendAction({ action: 'getSalesOrder', probs: id })
			},
			getSalesOrderByLeadId: function (id) {
				//console.log('getSalesOrder'+ id)
				return SwService.sendAction({ action: 'getSalesOrderByLeadId', probs: id })
			},
			getAllStaff: function (){
				return SwService.sendAction({ action: 'getAllStaff' });
			},
			getUserData: function () {
				return SwService.sendAction({ action: 'getUserData', probs: localStorage.getItem('userId') });
			},
			getAlertMertics: function () {
				return SwService.sendAction({ action: 'getAlertMertics' })
			},
			getMemos: function () {
				return SwService.sendAction({ action: 'getMemos' })
			},
			getAllActions: function () {
				return SwService.sendAction({ action: 'getAllActions' })
			},
			getMemosByType: function (memo) {
				return SwService.sendAction({ action: 'getMemosByType', probs: memo })
			},
			getMemoByKey: function () {
				return SwService.sendAction({ action: 'getMemo', probs: memo })

			},
			getRemindersMertics: function () {
				return SwService.sendAction({ action: 'getRemindersMertics' })
			},
			getAppointments: function () {
				return SwService.sendAction({ action: 'getAppointments' });
			},
			getEnquirys: function () {
				return SwService.sendAction({ action: 'getEnquirys' });
			},
			updateEnquiry: function (id) {
				return SwService.sendAction({ action: 'updateEnquiry', probs: id });
			},
			sendMemo: function (memo) {
				return SwService.sendAction({ action: 'addMemo', probs: memo });
			},
			addAppointment: function (appointment) {
				return SwService.sendAction({ action: 'addAppointment', probs: appointment });
			},
			addSalesOrder: function (salesOrder) {
				return SwService.sendAction({ action: 'addSalesOrder', probs: salesOrder });
			},
			updateLeadProfile: function (leadProfile) {
				return SwService.sendAction({ action: 'updateLeadProfile', probs: leadProfile });
			},
			updateSalesOrder: function (salesOrder) {
				return SwService.sendAction({ action: 'updateSalesOrder', probs: salesOrder });
			},
			updateAppointment: function (appointment) {
				return SwService.sendAction({ action: 'updateAppointment', probs: appointment });
			},
			getParams: function () {
				return SwService.sendAction({ action: 'getParams' });
			},
			getNotesOrderId: function (orderId) {
				return SwService.sendAction({ action: 'getNotesOrderId', probs: orderId });
			},
			addNote: function (newNote, OrderId) {
				return SwService.sendAction({ action: 'addNote', probs: { newNote: newNote, OrderId: OrderId } });
			},
			getOrderlines: function (orderId) {
				return SwService.sendAction({ action: 'getOrderlines', probs: orderId });
			},
			updateOrderline: function (orderline) {
				return SwService.sendAction({ action: 'updateOrderline', probs: orderline });
			},
			addOrderline: function (orderline, orderId) {
				return SwService.sendAction({ action: 'addOrderline', probs: { orderline: orderline, orderId: orderId } });
			},
			deleteOrderline: function (lineId) {
				return SwService.sendAction({ action: 'deleteOrderline', probs: lineId });
			},
			getLeadDifficulty: function (id) {
				return SwService.sendAction({ action: 'getLeadDifficulty', probs: id });
			},
			toggleLeadDifficulty: function (id) {
				return SwService.sendAction({ action: 'toggleLeadDifficulty', probs: id });
			},
			setLeadDifficulty: function (id, newDifficulty) {
				return SwService.sendAction({ action: 'setLeadDifficulty', probs: { id: id, newDifficulty: newDifficulty } });
			},
			addSurveyMaintenance: function (data) {
				return SwService.sendAction({action:'addSurveyMaintenance', probs:data});
			},
			getSurveyMaintenance: function (id) {
				return SwService.sendAction({action:'getSurveyMaintenance', probs:id});
			},
			getSurveyMaintenanceByOrder: function (orderId) {
				return SwService.sendAction({action:'getSurveyMaintenanceByOrder', probs:orderId});
			},
			getAllSurveyMaintenance: function () {
				return SwService.sendAction({action:'getAllSurveyMaintenance'});
			},
			updateSurveyMaintenance:function(surveyMaintenance){
				return SwService.sendAction({action:'updateSurveyMaintenance', probs:surveyMaintenance});
			},
			deleteSurveyMaintenance:function(id){
				return SwService.sendAction({action:'deleteSurveyMaintenance', probs:id});
			},
			userLogout: function () {
				SwService.sendAction({ action: 'userLogout', probs: localStorage.getItem('userId') });
				localStorage.removeItem('userId');
			},
			customerSearch:function(searchData){
				return SwService.sendAction({action:'customerSearch', probs:searchData});
			},

			setUser:function(data){
				return SwService.sendAction({
					action: 'setUser',
					probs: Object.assign(data, { Token: SessionService.getToken() })
				});
			},
			// Be Careful with this method
			clearDB: function () {
				SwService.sendAction({ action: 'clearDB' });
			}

		}
		// Polls API for a connection to the API
		ipadService.setOfflineFlag();
		setInterval(ipadService.setOfflineFlag, 1000*60*5) // 1000ms * 60 * 5 = 5 mins
		return ipadService;
	}]);
